@font-face {
    font-family: "Rexton";
    src: url('./assets/fonts/Rexton Light.otf');
    font-weight: light;
    font-style: normal;
}

@font-face {
font-family: "Rexton";
src: url('./assets/fonts/Rexton Medium.otf');
font-weight: normal;
font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url('./assets/fonts/Cera\ Pro\ Black.otf');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url('./assets/fonts/Cera\ Pro\ Bold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url('./assets/fonts/Cera\ Pro\ Light.otf');
    font-weight: light;
    font-style: normal;
}

@font-face {
    font-family: "Cera Pro";
    src: url('./assets/fonts/Cera\ Pro\ Medium.otf');
    font-weight: normal;
    font-style: normal;
}
